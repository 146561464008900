import Layout from "components/layout"
import Markdown from "components/markdown"
import React from "react"
import { JsonLd } from "react-schemaorg"
import { FAQPage } from "schema-dts"

const md = `
# Help & Contacts

### FAQs

<question id={1}>
How is Stay or Go different from other product comparison sites?
</question>
<answer id={1}>
  Unlike many comparison sites, Stay or Go works to get you a better deal with your current bank so you may not need to change providers or reapply for credit. At the same time, we compare relevant offers from across the market, including special deals that are not publicly available. Importantly, rather than just comparing introductory offers, we aim to find you a great long-term solution, so you don’t have to continually shop around.
</answer>
<question id={2}>
How much does it cost to use Stay or Go?
</question>
<answer id={2}>
    Stay or Go is a free service, so [get started](/credit-cards/instructions) now to find out how much you could save.
</answer>
<question id={3}>
How long does the Stay or Go review take?
</question>
<answer id={3}>
    It takes about 60 seconds on average to get your offers. Its fast. Really fast.
</answer>
<question id={4}>
What products does Stay or Go review?
</question>
<answer id={4}>
  Credit cards are our immediate focus. In the future, we plan to help you with other banking products too.
</answer>
<question id={5}>
What are special offers?
</question>
<answer id={5}>
  Special offers are exclusive deals that are not publicly available. These deals are only available to certain customers, for example a bank’s existing customers. This is in contrast to standard offers found on typical comparison sites and bank websites. Standard offers are publicly advertised and available to anyone who applies and is approved. Special offers are generally better than standard offers.
</answer>
<question id={6}>
How does Stay or Go get a better deal from my existing bank?
</question>
<answer id={6}>
  With the information you provide us, we review your bank’s product range to find other options that may better match your requirements, and we work to access special deals that may not be available to other customers.
</answer>
<question id={7}>
How does Stay or Go make money?
</question>
<answer id={7}>
  Stay or Go is free for consumers. We instead make money by offering services to banks and other third-parties, including the ability to advertise their products on our website. We may charge third-parties a fee or commission when you apply or are approved for a credit card offer through our website, or when you use links to third-party websites. For further detail, please refer to our [Terms of Use](/terms).
</answer>
<question id={8}>
How do you calculate the savings and benefits?
</question>
<answer id={8}>
  We take the following approach when estimating your savings and benefits:

  * Estimated benefits are calculated over a 3-year period to ensure results reflect longer term suitability rather than introductory offers alone.
  * Calculations are based on the information you have provided, and we assume that your behaviours and balance will not change.
  * We assume your account does not have additional cardholders. If it does, then estimated benefits may vary if additional cardholder fees apply.
  *	We assume your overseas spend proportion is consistent with the Australian population, as published by the [Reserve Bank of Australia](https://rba.gov.au/payments-and-infrastructure/resources/payments-data.html), including consideration of COVID-19 impacts.
  * If an offer requires you to open a new credit card account, we assume you will close your existing credit card account within 10 business days.
  * If you don’t always pay off your balance in full, we assume that you will utilise balance transfer and balance payment plan offers, where available.
  * We assume you will repay any balance transfer offer in equal monthly instalments over the term of the offer or over 12 months, whichever is longer.
  * We assume you will repay any balance payment plan offer in equal monthly instalments over the term of the offer.
  * We assume you will qualify for any cash back offers, and unless your card spend is zero, we assume you qualify for any bonus points offers.
  * Within the limits on any reward program spend caps, we assume all your purchases are eligible to earn reward points.
  * When expressing the value of reward points in dollars, we assess the number of points required to redeem popular retail gift cards.
</answer>
<question id={9}>
How accurate is the benefits estimate you provide?
</question>
<answer id={9}>
  The estimate is produced through our sophisticated algorithm that uses the information you share with us, and detailed information we hold and maintain across the market. The process has been designed to provide a quick and easy way to understand your likely benefit outcome, and if you might benefit from Stay or Go. The full Stay or Go service, which will launch in the coming months, will provide a very accurate estimate of the benefits you could achieve.
</answer>
<question id={10}>
What experience do you have in credit cards?
</question>
<answer id={10}>
  The [Stay or Go team](/about) are experts in credit cards, and banking more generally. Our team members have led some of Australia’s largest credit card businesses and the introduction of many market innovations, including contactless payments, Apply Pay, 0% balance transfers, and market leading low rate and rewards cards. We have a deep understanding of how the industry works, and are excited to be helping customers access a better deal through Stay or Go.
</answer>
<question id={11}>
What does Stay or Go do with my information?
</question>
<answer id={11}>
  The information you provide to Stay or Go is used to get you a better deal with your existing bank, and to access special offers from new banks. It’s also used to evaluate offers, identify those that match your requirements, and present you with an estimate of the savings and benefits you could achieve. Please review our [Privacy Policy](/privacy) for more information.
</answer>
<question id={12}>
How do I share my ideas and feedback with Stay or Go?
</question>
<answer id={12}>
  We love feedback, so feel free to email us at <info@stayorgo.com.au>
</answer>
<question id={13}>
If I have an issue, how do I raise this to someone at Stay or Go?
</question>
<answer id={13}>
  The best way to get help is to contact us by email at <info@stayorgo.com.au>. We will keep you updated on our review of any complaint or issue you raise. If after making a complaint you are not satisfied with the outcome, you can lodge a complaint with the Australian Financial Complaints Authority (AFCA). AFCA provides fair and independent financial services complaint resolution that is free to consumers.

  Website: [afca.org.au](https://afca.org.au/)

  Email: <info@afca.org.au>

  Phone: 1800 931 678

  Mail: Australian Financial Complaints Authority, GPO Box 3, Melbourne VIC 3001
</answer>


### Contact us

If you can’t find the information you need here, or you have feedback, we’d love here from you.

![email:](/images/email_icon.png) <info@stayorgo.com.au>

![mail:](/images/mail_icon.png) Stay or Go, Stone & Chalk, 121 King St, Melbourne VIC 3000

`

const questions = []
const answers = []

const char = "\n"
let i = 0
let j = 0

while ((j = md.indexOf(char, i)) !== -1) {
  const line = md.substring(i, j)
  if (line.match(/^<question id={\d+}>$/)) {
    i = j + 1
    j = md.indexOf(char, i)

    const q = md.substring(i, j)
    questions.push(q)
  } else if (line.match(/<answer id={\d+}>/)) {
    i = j + 1
    j = md.indexOf(char, i)

    const a = md.substring(i, j)
    answers.push(a)
  }
  i = j + 1
}

const mainEntity: SchemaValue<Thing | IdReference, "mainEntity"> = []
if (questions.length === answers.length) {
  for (let i = 0; i < questions.length; i++) {
    const entity = {
      "@type": "Question",
      name: questions[i],
      acceptedAnswer: {
        "@type": "Answer",
        text: answers[i],
      },
    }
    mainEntity.push(entity)
  }
}

export default function Help() {
  return (
    <Layout title="Your FAQs answered | Stay or Go" description="Need help, want to understand more about Stay or Go, or have feedback? Read our FAQs or get in contact with us - we'd love to hear from you!" image="/images/Logo_SOG_Colour.png">
      <JsonLd<FAQPage>
        item={{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: mainEntity,
        }}
      />
      <Markdown md={md} isArticle={false} />
    </Layout>
  )
}
